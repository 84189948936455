import { FormControlData, FormControlValue, CreateFormControlProps, ICollectionPhoneInput } from "../model";
import { createFormControl } from "solid-forms";
import { Label, Error, PhoneInput } from "../views";

export function createPhoneInput<V extends FormControlValue, D extends FormControlData = FormControlData>(
  ...args: CreateFormControlProps<V, D>
): ICollectionPhoneInput<V, D> {
  const control = createFormControl<V, D>(...args);
  const name = String(control.id);
  return {
    control,
    name,
    Label: (props) => <Label control={control} for={name} {...props} />,
    Error: (props) => <Error control={control} {...props} />,
    PhoneInput: (props) => <PhoneInput control={control} name={name} {...props} />,
  };
}
